$(".header .btn-menu").click(function () {
	$(".header .btn-menu").toggleClass('open');
    $(".header .sp-menu").toggleClass('open');
});

$(".header .sp-menu a").click(function () {
    $(".header .btn-hamburger").removeClass('open');
    $(".header .sp-menu").removeClass('open');
});

$(window).resize(function(){
    if( 940 <= $(window).width() ) {
        $(".header .btn-menu").removeClass('open');
        $(".header .sp-menu").removeClass('open');
    }
});