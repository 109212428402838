const swiper = new Swiper(".swiper", {
    loop: true,
    loopAdditionalSlides: 2,
    centeredSlides: true,
    spaceBetween: 48,
    slidesPerView: 3,
    speed: 1000,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});