$(function() {
    if($(window).scrollTop() > 50) {
        $('.header .logo').addClass('scroll');
        $('.header .pc-top-logo').addClass('scroll');
    } else {
        $('.header .logo').removeClass('scroll');
        $('.header .pc-top-logo').removeClass('scroll');
    }
    $(window).scroll(function () {
        if($(window).scrollTop() > 50) {
            $('.header .logo').addClass('scroll');
            $('.header .pc-top-logo').addClass('scroll');
        } else {
            $('.header .logo').removeClass('scroll');
            $('.header .pc-top-logo').removeClass('scroll');
        }
    });
});